import ApiService from "@/core/services/ApiService";
interface IPengguna {
  username: string;
  email: string;
  full_name: string;
  disabled: boolean;
  photo: string;
  role: string;
  _id: string;
}

const pengguna: Array<IPengguna> = [];
export { IPengguna };

export default pengguna;

export const getPengguna = async () => {
  const response = await ApiService.get("/user");
  return response.data;
};

export const savePengguna = async (loadout) => {
  const response = await ApiService.post("/user/save", { data: loadout });
  return response;
};
export const deletePengguna = async (loadout, id) => {
  const response = await ApiService.post("/user/delete/" + id, {
    data: loadout,
  });
  return response;
};
export const updatePengguna = async (loadout) => {
  const response = await ApiService.post("/user/edit/" + loadout._id, {
    data: loadout,
  });
  return response;
};
export const resetPasswordPengguna = async (loadout) => {
  const response = await ApiService.post("/user/reset-password", {
    data: loadout,
  });
  return response;
};
