
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import JwtService from "@/core/services/JwtService";
import pengguna, { savePengguna } from "./servicePengguna";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ElMessageBox } from "element-plus";
import ApiService from "@/core/services/ApiService";
interface UserForm {
  username: string;
  full_name: string;
  email: string;
  password: string;
  disabled: boolean;
  photo: string;
  role: 0;
}

export default defineComponent({
  name: "input-user",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitSave = ref<HTMLElement | null>(null);
    const loading = ref(false);
    const router = useRouter();
    const file_list = ref([]);
    const file_upload_header = {
      Authorization: `Bearer ${JwtService.getToken()}`,
    };
    const actionURL = `${process.env.VUE_APP_API_URL}/streams/uploadfile`;
    const UserValidator = Yup.object().shape({
      fullname: Yup.string().required().label("Fullname"),
      username: Yup.string().required().label("Username"),
      role: Yup.string().required().label("Role"),
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(6).required().label("Passwowrd"),
    });

    const UserForm = ref<UserForm>({
      username: "",
      full_name: "",
      email: "",
      password: "",
      disabled: false,
      photo: "",
      role: 0,
    });

    const simpanUser = async () => {
      if (submitSave.value) {
        submitSave.value.setAttribute("data-kt-indicator", "on");
        let loadout = UserForm.value;
        const result = await savePengguna(loadout);
        if (result.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "succerr",
            toast: true,
            title: "Berhasil! Pengguna berhasil ditambahkan",
            showConfirmButton: false,
            timer: 3000,
          });

          router.push({ name: "users" });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            toast: true,
            title: "Gagal! Terjadi kesalahan saat menambahkan pengguna",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        submitSave.value?.removeAttribute("data-kt-indicator");
      }
    };

    const removeImage = () => {
      UserForm.value.photo = "media/avatars/blank.png";
    };

    const formatFileSource = (file_list) => {
      let file_source = "";
      for (let i = 0; i < file_list.length; i++) {
        if (i == 0) {
          file_source = file_list[i].response.file;
        } else {
          file_source = file_source + "," + file_list[i].response.file;
        }
      }
      return file_source;
    };

    const setFileUpload = (response, file, fileList) => {
      file_list.value = fileList;
      console.log(fileList);
    };

    const errorFileUpload = (error, file, fileList) => {
      let message = JSON.parse(error.message);
      ElMessageBox.alert("Silakan periksa inputan Anda.", message.detail, {
        confirmButtonText: "OK",
        type: "warning",
        center: true,
      });
    };

    const removeFileUpload = (file, fileList) => {
      const file_to_remove = file.response.file;
      ApiService.post("/streams/removefile", { data: { path: file_to_remove } })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const cekFileSource = (file, fileList) => {
      if (fileList.length == 0) {
        UserForm.value["photo"] = "";
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Tambah User", ["User"]);
    });

    return {
      UserForm,
      submitSave,
      UserValidator,
      removeImage,
      simpanUser,
      file_upload_header,
      setFileUpload,
      formatFileSource,
      errorFileUpload,
      removeFileUpload,
      cekFileSource,
      actionURL,
    };
  },
  computed: {
    acceptFile() {
      let str_accept_file = "image/png, image/jpeg, image/jpg";
      if (this.UserForm["photo"] == "media_tv") {
        str_accept_file = "video/mp4,video/x-m4v";
      }
      return str_accept_file;
    },
  },
});
